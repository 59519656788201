<template>
	<div class="page-download-purchased">
		<div class="container">
			<h1>Download purchased</h1>
			<h6>You can always find these again in your Library</h6>
			<template v-if="PURCHASES_LOADER">
				<v-skeleton-loader
					type="image"
					class="item_skeleton_purchased"
					v-for="index in 10"
					:key="index"
				></v-skeleton-loader>
			</template>
			<div class="wrapper_download_purchase" v-if="!PURCHASES_LOADER">

				<template v-if="PURCHASES.length">
					<div class="item_purchased" v-for="item of PURCHASES" :key="item.product_id">
						<div class="image">
							<img :src="item.image" alt="">
						</div>
						<div class="name">
							<span v-if="item.product_type === 'music_release'" @click="redirectToAlbum(item)">{{ item.title }}</span>
							<span v-if="item.product_type === 'song'" @click="redirectToAlbum(item)">{{ item.title }}</span>
						</div>
						<div class="artist">
							<!--							<template v-if="item.product_type === 'music_release'">-->
							<div @click="redirectToArtistPage(item.artist.id, item.artist.identifier)" v-if="item.artist">{{ item.artist.name }}</div>
							<span v-else>VA</span>
							<!--							</template>-->
						</div>
						<div class="released">{{ item.releasedAt | moment("MMM YYYY") }}</div>
						<div class="actions">
							<button type="button" class="download" v-ripple @click="download(item)" :class="{loading: loaderDownload === item.product_id}">
								<v-progress-circular :width="2" indeterminate></v-progress-circular>
								Download
							</button>
						</div>
					</div>
				</template>

				<template v-if="!PURCHASES.length">
					<nothingHereYet></nothingHereYet>
				</template>

			</div>


		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import nothingHereYet from "@/components/small/nothingHereYet.vue";

export default {
	name: "downloadPurchased",
	components: {
		nothingHereYet
	},
	data() {
		return {
			loaderDownload: null,
		}
	},
	computed: {
		...mapGetters(['PURCHASES', 'PURCHASES_LOADER',
			'MUSIC_RELEASE_DOWNLOAD_DATA', 'DOWNLOAD_MUSIC_RELEASE_LINK',
			'SONG_DOWNLOAD_DATA', 'DOWNLOAD_SONG_LINK',
		])
	},
	mounted() {
		let params = {last: true}
		this.GET_PURCHASES(params)
			.then(() => {

			})
			.catch(err => console.log(err));
	},
	methods: {
		...mapActions(['GET_PURCHASES',
			'GET_DOWNLOAD_MUSIC_RELEASE_LINK',
			'GET_DOWNLOAD_SONG_LINK'
		]),
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		},
		redirectToAlbum(musicRelease) {
			console.log('musicRelease', musicRelease);
			// const albumTitle = musicRelease.title.trim().replace(/ /g, '-');
			this.$router.push({name: 'albumDetails', params: {name: window.toHumanFormat(musicRelease.identifier)}});
		},
		download(item) {
			// console.log('product_type-', item.product_type); // product_type
			this.loaderDownload = item.product_id;
			if (item.product_type === 'music_release') {
				let albumID = item.product_id;
				let albumTitle = item.title;
				this.$store.commit('SET_MUSIC_RELEASE_DOWNLOAD_DATA', {id: albumID, title: albumTitle});

				this.GET_DOWNLOAD_MUSIC_RELEASE_LINK(this.MUSIC_RELEASE_DOWNLOAD_DATA.id)
					.then(() => {
						if (this.DOWNLOAD_MUSIC_RELEASE_LINK) {
							let link = document.createElement('a');
							link.href = this.DOWNLOAD_MUSIC_RELEASE_LINK;
							link.download = `${this.MUSIC_RELEASE_DOWNLOAD_DATA.title}`;
							link.click();
						}
					})
					.catch(err => {
						console.error(`download-purchased, download music-release, ${err}`);
					})
					.finally(() => {
						this.loaderDownload = null;
					})
			}

			if (item.product_type === 'song') {
				let id = item.product_id;
				let artist = item.artist.name;
				let title = item.title;

				this.$store.commit('SET_SONG_DOWNLOAD_DATA', {id: id, artist: artist, title: title})
				this.GET_DOWNLOAD_SONG_LINK(this.SONG_DOWNLOAD_DATA.id)
					.then(() => {
						if (this.DOWNLOAD_SONG_LINK) {
							this.downloadTrackDialogVisible = false;
							let link = document.createElement('a');
							link.href = this.DOWNLOAD_SONG_LINK;
							link.download = `${this.SONG_DOWNLOAD_DATA.artist} - ${this.SONG_DOWNLOAD_DATA.name}.flac`;
							link.click();
						}
					})
					.catch(err => {
						console.error(`download-purchased, download track, ${err}`);
					})
					.finally(() => {
						this.loaderDownload = null;
					})
			}

		}
	}
}
</script>

<style lang="scss">

</style>